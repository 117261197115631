import React, { useContext, useState, useEffect, useRef } from "react";
import { BiLoaderAlt } from "react-icons/bi";
import { FaLightbulb } from "react-icons/fa";
import AIChatContext from "../../../../context/ChatContext";
import useNewChat from "../../../../hooks/useNewChat";

const AiPromptSelected = ({ aiPrompt }) => {

  const handleNewChat = useNewChat();
  const { isWorking, setIsWorking, cancelAxiosRequest } = useContext(AIChatContext);
  const [showConfirm, setShowConfirm] = useState(false);
  const confirmRef = useRef(null);

  const handleClick = (e) => {
    e.stopPropagation();
    setShowConfirm(true);
  };

  const confirmAction = (e) => {
    e.stopPropagation();
    setShowConfirm(false);
    const regenContentWithPrompt = true;
    handleNewChat(aiPrompt, null, null, null, regenContentWithPrompt);
  };

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (confirmRef.current && !confirmRef.current.contains(e.target)) {
        setShowConfirm(false);
      }
    };

    if (showConfirm) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showConfirm]);



  useEffect(() => {

  }, [aiPrompt]);

  return (
    <div className="relative flex items-center">
      {!isWorking && (
        <button
          className={`rounded-full w-6 h-6 flex justify-center items-center shadow-lg transition-all 
      ${aiPrompt
              ? "bg-gradient-to-r from-blue-600 via-indigo-500 to-purple-500 shadow-blue-500/50 hover:from-blue-500 hover:via-indigo-400 hover:to-purple-400"
              : "bg-gradient-to-r from-gray-800 via-gray-700 to-gray-600 shadow-gray-500/50"}`}
          onClick={handleClick}
        >
          <FaLightbulb className="w-4 h-4 text-white" />
        </button>
      )}

      {isWorking && (
        <button
          className="bg-gradient-to-r from-blue-600 via-indigo-500 to-purple-500 rounded-full w-6 h-6 flex justify-center items-center shadow-lg shadow-blue-500/50 hover:from-blue-500 hover:via-indigo-400 hover:to-purple-400 transition-all"
          onClick={() => {
            cancelAxiosRequest();
            setIsWorking(false);
          }}
        >
          <BiLoaderAlt className="w-5 h-5 animate-spin text-white" />
        </button>
      )}

      {showConfirm && (
        <div
          ref={confirmRef}
          className="absolute text-center bottom-full left-1/2 -translate-x-1/2 -mb-2 items-center bg-black text-white rounded px-2 py-2 border-2 border-purple-600 text-xs shadow-lg shadow-zinc-800 whitespace-nowrap"
        >
          {aiPrompt ? (
            <>
              <p>Regenerate content?</p>
              <p className="mt-2">
                <button
                  className="border border-gray-700 text-white px-2 py-0.5 rounded text-xs mr-1"
                  onClick={() => setShowConfirm(false)}
                >
                  No
                </button>
                <button
                  className="bg-gradient-to-r from-blue-600 via-indigo-500 to-purple-500 text-white px-2 py-0.5 rounded text-xs shadow-md shadow-blue-500/50 hover:from-blue-500 hover:via-indigo-400 hover:to-purple-400 transition-all"
                  onClick={confirmAction}
                >
                  Yes
                </button>
              </p>
            </>
          ) : (
            <p>A prompt and context are required to auto-generate content.</p>
          )}
        </div>
      )}
    </div>
  );
};

export default AiPromptSelected;
