import React, { useState } from "react";
import PropOpen from "./PropOpen";
import CustomCss from "./CustomCss";
// import ClassesChips from "./ClassesChips";

const CustomCssSection = () => {
  const [open, setOpen] = useState(true);
  return (
    <PropOpen title={"Custom Styles"} open={open} setOpen={setOpen}>
      <CustomCss />
    </PropOpen>
  );
};

export default CustomCssSection;
