import React, { useContext, useEffect, useRef, useState } from "react";
import {
  TbFileDescription,
  TbSquareRoundedArrowUpFilled,
} from "react-icons/tb";
import { LuImage } from "react-icons/lu";
import { RxCross2 } from "react-icons/rx";
import CanvasContext from "../../../../context/CanvasContext";
import useNewChat from "../../../../hooks/useNewChat";
import axios from "../../../../api/axios";

const ChatInput = () => {
  const { pageState, selectedState, setSelectedState } =
    useContext(CanvasContext);
  const handleNewChat = useNewChat();
  const [fileName, setFileName] = useState("");
  const [inputText, setInputText] = useState("");
  const [isFocused, setIsFocused] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isDragOver, setIsDragOver] = useState(false);
  const [previewUrl, setPreviewUrl] = useState(null);
  const fileInputRef = useRef(null);
  const pdfInputRef = useRef(null); // Reference for PDF input
  const textareaRef = useRef(null);
  const [sseResponse, setSseResponse] = useState("");

  useEffect(() => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = "auto"; // Reset height to recalculate
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  }, [inputText]);

  const handleFileInput = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedFile(file);
      if (file.type.startsWith("image/")) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setPreviewUrl(reader.result);
        };
        reader.readAsDataURL(file);
        setFileName(""); // Clear fileName for image files
      } else {
        setPreviewUrl(null); // No preview for non-image files
        setFileName(file.name);
      }
    }
    e.target.value = null;
  };

  const handlePdfInput = (e) => {
    const file = e.target.files[0];
    if (file && file.type === "application/pdf") {
      setSelectedFile(file); // Only set if it's a PDF file
      setPreviewUrl(null); // No preview for PDFs
      setFileName(file.name);
    } else {
      alert("Please select a PDF file.");
    }
    e.target.value = null;
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragOver(true);
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = () => {
    setIsDragOver(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragOver(false);
    const file = e.dataTransfer.files[0];
    handleFileInput({ target: { files: [file] } });
  };

  const handlePaste = (event) => {
    const items = event.clipboardData.items || event.clipboardData;
    for (const item of items) {
      if (item.type.indexOf("image") === 0) {
        const blob = item.getAsFile();
        setSelectedFile(blob);
        const reader = new FileReader();
        reader.onloadend = () => {
          setPreviewUrl(reader.result);
        };
        reader.readAsDataURL(blob);
        setFileName(""); // Clear fileName for pasted images
      }
    }
  };

  const handlePdfUpload = async () => {
    if (selectedFile) {
      const formData = new FormData();
      formData.append("file", selectedFile);

      const uploadUrl = "http://localhost:3500/website/pdf/importt";

      try {
        // Step 1: Upload the PDF file
        const options = {
          method: "POST",
          body: formData,
          headers: {
            Authorization:
              "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJVc2VySW5mbyI6eyJlbWFpbCI6InNhaWZ1bGxhaC5hbGFtNTUyQGdtYWlsLmNvbSIsInJvbGVzIjp7IlVzZXIiOnRydWUsIkJhc2ljIjp0cnVlLCJQcm8iOnRydWUsIkVudGVycHJpc2UiOmZhbHNlLCJBZG1pbiI6ZmFsc2V9fSwiaWF0IjoxNzM0NDMxNTYxLCJleHAiOjE3MzQ2OTA3NjF9.Q9MQpyU0S977WJqprcHn2tMEjPMJu-zrqQycy0HvuWg",
          },
        };

        console.log("Starting file upload");

        // Step 2: Fetch the SSE response after file upload
        const resp = await fetch(uploadUrl, options);
        if (!resp.ok) {
          throw new Error(`Failed to upload file: ${resp.statusText}`);
        }

        // Step 3: Handle the SSE event stream manually
        let eventBuffer = ""; // To accumulate chunks
        const reader = resp.body.getReader();

        while (true) {
          const { done, value } = await reader.read();
          if (done) break;

          const str = new TextDecoder().decode(value);
          // console.log("Raw SSE chunk:", str);

          // Accumulate the chunk data
          eventBuffer += str;

          // If we have a complete event (ends with '\n\n'), process it
          while (eventBuffer.includes("\n\n")) {
            const eventEndIndex = eventBuffer.indexOf("\n\n");
            const eventData = eventBuffer.slice(0, eventEndIndex);

            // Process the event (extract the 'data' part)
            const event = parseEvent(eventData);
            try {
              if (event.data === "[DONE]") {
                return;
              }
              const parsedChunk = JSON.parse(event.data);
              setSseResponse((prev) => prev + parsedChunk?.chunk);
              console.log("Response of chunks", sseResponse);
            } catch (error) {
              console.error("error", error);
            }

            // Remove the processed event from the buffer
            eventBuffer = eventBuffer.slice(eventEndIndex + 2);
          }
        }
      } catch (error) {
        console.error("Error uploading file or handling SSE response:", error);
        alert("An error occurred. Please try again.");
      }
    } else {
      alert("Please select a PDF file.");
    }
  };

  // Helper function to manually parse the SSE event data
  function parseEvent(eventData) {
    const eventLines = eventData.split("\n");
    let event = { data: null };

    eventLines.forEach((line) => {
      if (line.startsWith("data: ")) {
        event.data = line.slice(6); // Extract the data part
      }
    });

    return event;
  }

  console.log(sseResponse);

  return (
    <div
      className={`relative border ${
        isDragOver
          ? "border-lime-400"
          : isFocused
          ? "border-lime-600"
          : "border-zinc-700"
      } p-1 rounded-xl px-2 mr-8`}
      onDragOver={handleDragOver}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      {(previewUrl || fileName || selectedState.key !== pageState.key) && (
        <span className="text-sm text-zinc-400 px-1">Attached:</span>
      )}
      <div className="flex items-center">
        {previewUrl && (
          <div className="h-16 w-fit my-2 mb-4 mx-1 relative group">
            <img
              src={previewUrl}
              alt="upload preview"
              className="h-16 w-auto rounded-lg border-[0.5px] border-lime-600 hover:border-lime-500 duration-200"
            />
            <button
              className="absolute top-[-5px] right-[-5px] rounded-full border border-zinc-600 bg-zinc-800 hidden group-hover:block"
              onClick={() => {
                setSelectedFile(null);
                setPreviewUrl(null); // Reset preview
                setFileName(""); // Reset fileName
              }}
            >
              <RxCross2 className="w-4 h-4 text-zinc-300" />
            </button>
          </div>
        )}
        {!previewUrl && fileName && (
          <div className="h-16 flex items-center gap-2 my-2 mx-1">
            <span className="text-sm text-zinc-300">{fileName}</span>
            <button
              className="rounded-full border border-zinc-600 bg-zinc-800 p-1"
              onClick={() => {
                setSelectedFile(null);
                setFileName(""); // Reset fileName
              }}
            >
              <RxCross2 className="w-4 h-4 text-zinc-300" />
            </button>
          </div>
        )}
        {selectedState.key !== pageState.key && (
          <div className="my-2 mx-1 relative group w-fit flex items-center gap-2">
            <div className="rounded-md text-sm py-1 px-2 text-zinc-300 bg-zinc-900 font-bold tracking-tighter border border-[#40e0d0] hover:border-lime-600">
              {selectedState.key.split("_")[0]}
            </div>
            <button
              className="absolute top-[-5px] right-[-5px] rounded-full border border-zinc-600 bg-zinc-800 hidden group-hover:block"
              onClick={() => {
                setSelectedState(pageState);
              }}
            >
              <RxCross2 className="w-4 h-4 text-zinc-300" />
            </button>
          </div>
        )}
      </div>

      <div className="flex items-end">
        <input
          ref={fileInputRef}
          type="file"
          accept="image/*"
          onChange={handleFileInput}
          style={{ display: "none" }}
        />
        <button
          className="mb-2"
          onClick={() => {
            fileInputRef.current.click();
          }}
        >
          <LuImage className="w-6 h-6 text-zinc-400 mx-1" />
        </button>

        <input
          ref={pdfInputRef}
          type="file"
          accept=".pdf"
          onChange={handlePdfInput}
          style={{ display: "none" }}
        />

        <textarea
          ref={textareaRef}
          rows={1}
          value={inputText}
          onChange={(e) => {
            if (e.target.value !== "\n") setInputText(e.target.value);
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter" && !e.shiftKey)
              handleNewChat(
                inputText,
                setInputText,
                selectedFile,
                setSelectedFile
              );
          }}
          className="outline-none bg-transparent text-md p-2 px-5 text-zinc-100 flex-grow font-secondary placeholder:text-zinc-500 placeholder:text-sm resize-none max-h-32 customScroll"
          type="text"
          placeholder="Type your instructions here.."
          onPaste={handlePaste}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
        />
        <div className="relative group inline-block">
          <button
            className="mb-2"
            onClick={() => {
              pdfInputRef.current.click();
            }}
          >
            <TbFileDescription className="w-6 h-6 text-zinc-400 mx-1" />
          </button>
          <span className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 hidden group-hover:block bg-gray-800 text-white text-xs rounded px-2 py-1 whitespace-nowrap">
            Upload PDF Context
          </span>
        </div>

        <button
          className="disabled:text-zinc-500 text-lime-600 mb-1"
          onClick={() => {
            handlePdfUpload();
            handleNewChat();
          }}
          // Use the new handlePdfUpload function
          disabled={!inputText && !selectedFile}
        >
          <TbSquareRoundedArrowUpFilled className="w-8 h-8 " />
        </button>
      </div>
    </div>
  );
};

export default ChatInput;
