import chroma from "chroma-js";
import colors from "tailwindcss/colors";

export function rgbaToHex(rgba) {
  // Extract the numeric parts of the rgba string
  const parts = rgba.match(
    /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+\.?\d*))?\)$/
  );

  // If the parsing failed, return a fallback or an error
  if (!parts) {
    console.error("Invalid RGBA color:", rgba);
    return "#000000"; // Fallback color
  }

  // Convert each color component into a hex string
  const r = parseInt(parts[1]).toString(16).padStart(2, "0");
  const g = parseInt(parts[2]).toString(16).padStart(2, "0");
  const b = parseInt(parts[3]).toString(16).padStart(2, "0");

  // Combine them and return the result
  return `#${r}${g}${b}`;
}

export const blogArchiveCode = {
  compType: "html",
  compName: "div",
  compProps: {},
  eventProps: {},
  className:
    "w-screen min-h-screen bg-background text-foreground font-body flex flex-col items-center relative",
  style: {},
  script: "",
  bodyStyle: "",
  children: [
    {
      compType: "html",
      compName: "div",
      compProps: {},
      eventProps: {},
      className: "bg-background text-foreground font-body w-screen",
      style: {},
      script: "",
      bodyStyle: "",
      children: [],
    },
    {
      compType: "html",
      compName: "div",
      compProps: {},
      eventProps: {},
      className: "bg-background text-foreground font-body w-screen",
      style: {},
      script: "",
      bodyStyle: "",
      children: [],
    },
    {
      compType: "html",
      compName: "div",
      compProps: {},
      eventProps: {},
      className: "bg-background text-foreground font-body w-screen",
      style: {},
      script: "",
      bodyStyle: "",
      children: [],
    },
    {
      compType: "html",
      compName: "section",
      compProps: {},
      eventProps: {},
      className: "w-full ",
      style: {},
      script: "",
      bodyStyle: "",
      children: [],
    },
    {
      compType: "html",
      compName: "section",
      compProps: {},
      eventProps: {},
      className: "w-full ",
      style: {},
      script: "",
      bodyStyle: "",
      children: [],
    },
    {
      compType: "html",
      compName: "section",
      compProps: {},
      eventProps: {},
      className: "w-full ",
      style: {},
      script: "",
      bodyStyle: "",
      children: [],
    },
    {
      compType: "html",
      compName: "section",
      compProps: {},
      eventProps: {},
      className: "w-full ",
      style: {},
      script: "",
      bodyStyle: "",
      children: [],
    },
    {
      compType: "html",
      compName: "section",
      compProps: {},
      eventProps: {},
      className: "w-full ",
      style: {},
      script: "",
      bodyStyle: "",
      children: [],
    },
    {
      compType: "html",
      compName: "div",
      compProps: {},
      eventProps: {},
      className: "",
      style: {},
      script: "",
      bodyStyle: "",
      children: [
        {
          compType: "html",
          compName: "header",
          compProps: {},
          eventProps: {},
          className: "bg-gray-800 text-white py-6",
          style: {},
          script: "",
          bodyStyle: "",
          children: [],
        },
      ],
    },
    {
      compType: "html",
      compName: "div",
      compProps: {},
      eventProps: {},
      className: "",
      style: {},
      script: "",
      bodyStyle: "",
      children: [
        {
          compType: "html",
          compName: "header",
          compProps: {},
          eventProps: {},
          className: "bg-gray-800 text-white py-6",
          style: {},
          script: "",
          bodyStyle: "",
          children: [],
        },
      ],
    },
    {
      compType: "html",
      compName: "div",
      compProps: {},
      eventProps: {},
      className: "",
      style: {},
      script: "",
      bodyStyle: "",
      children: [
        {
          compType: "html",
          compName: "header",
          compProps: {},
          eventProps: {},
          className: "bg-gray-800 text-white py-6",
          style: {},
          script: "",
          bodyStyle: "",
          children: [
            {
              compType: "html",
              compName: "div",
              compProps: {},
              eventProps: {},
              className: "max-w-6xl mx-auto px-6",
              style: {},
              script: "",
              bodyStyle: "",
              children: [
                {
                  compType: "html",
                  compName: "h1",
                  compProps: {},
                  eventProps: {},
                  className: "text-3xl font-bold",
                  style: {},
                  script: "",
                  bodyStyle: "",
                  children: ["Blog Archive"],
                },
                {
                  compType: "html",
                  compName: "p",
                  compProps: {},
                  eventProps: {},
                  className: "mt-2 text-gray-300",
                  style: {},
                  script: "",
                  bodyStyle: "",
                  children: [
                    "Explore our latest blog posts, news, and updates",
                  ],
                },
              ],
            },
          ],
        },
        {
          compType: "html",
          compName: "main",
          compProps: {},
          eventProps: {},
          className: "max-w-6xl mx-auto p-6",
          style: {},
          script: "",
          bodyStyle: "",
          children: [
            {
              compType: "html",
              compName: "section",
              compProps: {},
              eventProps: {},
              className: "grid gap-8 lg:grid-cols-2 sm:grid-cols-1",
              style: {},
              script: "",
              bodyStyle: "",
              children: [
                {
                  compType: "html",
                  compName: "div",
                  compProps: {},
                  eventProps: {},
                  className:
                    "bg-white rounded-lg shadow-lg p-6 hover:shadow-xl transition-shadow",
                  style: {},
                  script: "",
                  bodyStyle: "",
                  children: [
                    {
                      compType: "html",
                      compName: "img",
                      compProps: {
                        src: "https://via.placeholder.com/500x250",
                        alt: "Blog post image",
                      },
                      eventProps: {},
                      className: "w-full h-56 object-cover rounded-md mb-4",
                      style: {},
                      script: "",
                      bodyStyle: "",
                      children: [],
                    },
                    {
                      compType: "html",
                      compName: "h2",
                      compProps: {},
                      eventProps: {},
                      className: "text-2xl font-bold mb-2",
                      style: {},
                      script: "",
                      bodyStyle: "",
                      children: [
                        "Understanding the Basics of Machine Learning",
                      ],
                    },
                    {
                      compType: "html",
                      compName: "p",
                      compProps: {},
                      eventProps: {},
                      className: "text-gray-700 mb-4",
                      style: {},
                      script: "",
                      bodyStyle: "",
                      children: [
                        "Discover the fundamentals of machine learning and how it's transforming industries worldwide.",
                      ],
                    },
                    {
                      compType: "html",
                      compName: "div",
                      compProps: {},
                      eventProps: {},
                      className: "text-gray-500 text-sm mb-4",
                      style: {},
                      script: "",
                      bodyStyle: "",
                      children: ["February 28, 2024 • 10 min read"],
                    },
                    {
                      compType: "html",
                      compName: "a",
                      compProps: { href: "#" },
                      eventProps: {},
                      className: "text-indigo-600 hover:underline",
                      style: {},
                      script: "",
                      bodyStyle: "",
                      children: ["Read More"],
                    },
                  ],
                },
                {
                  compType: "html",
                  compName: "div",
                  compProps: {},
                  eventProps: {},
                  className:
                    "bg-white rounded-lg shadow-lg p-6 hover:shadow-xl transition-shadow",
                  style: {},
                  script: "",
                  bodyStyle: "",
                  children: [
                    {
                      compType: "html",
                      compName: "img",
                      compProps: {
                        src: "https://via.placeholder.com/500x250",
                        alt: "Blog post image",
                      },
                      eventProps: {},
                      className: "w-full h-56 object-cover rounded-md mb-4",
                      style: {},
                      script: "",
                      bodyStyle: "",
                      children: [],
                    },
                    {
                      compType: "html",
                      compName: "h2",
                      compProps: {},
                      eventProps: {},
                      className: "text-2xl font-bold mb-2",
                      style: {},
                      script: "",
                      bodyStyle: "",
                      children: ["An Introduction to Deep Learning"],
                    },
                    {
                      compType: "html",
                      compName: "p",
                      compProps: {},
                      eventProps: {},
                      className: "text-gray-700 mb-4",
                      style: {},
                      script: "",
                      bodyStyle: "",
                      children: [
                        "Deep learning is revolutionizing artificial intelligence. Here’s what you need to know to get started.",
                      ],
                    },
                    {
                      compType: "html",
                      compName: "div",
                      compProps: {},
                      eventProps: {},
                      className: "text-gray-500 text-sm mb-4",
                      style: {},
                      script: "",
                      bodyStyle: "",
                      children: ["March 10, 2024 • 8 min read"],
                    },
                    {
                      compType: "html",
                      compName: "a",
                      compProps: { href: "#" },
                      eventProps: {},
                      className: "text-indigo-600 hover:underline",
                      style: {},
                      script: "",
                      bodyStyle: "",
                      children: ["Read More"],
                    },
                  ],
                },
                {
                  compType: "html",
                  compName: "div",
                  compProps: {},
                  eventProps: {},
                  className:
                    "bg-white rounded-lg shadow-lg p-6 hover:shadow-xl transition-shadow",
                  style: {},
                  script: "",
                  bodyStyle: "",
                  children: [
                    {
                      compType: "html",
                      compName: "img",
                      compProps: {
                        src: "https://via.placeholder.com/500x250",
                        alt: "Blog post image",
                      },
                      eventProps: {},
                      className: "w-full h-56 object-cover rounded-md mb-4",
                      style: {},
                      script: "",
                      bodyStyle: "",
                      children: [],
                    },
                    {
                      compType: "html",
                      compName: "h2",
                      compProps: {},
                      eventProps: {},
                      className: "text-2xl font-bold mb-2",
                      style: {},
                      script: "",
                      bodyStyle: "",
                      children: ["The Future of AI in Healthcare"],
                    },
                    {
                      compType: "html",
                      compName: "p",
                      compProps: {},
                      eventProps: {},
                      className: "text-gray-700 mb-4",
                      style: {},
                      script: "",
                      bodyStyle: "",
                      children: [
                        "AI is making a significant impact on healthcare. Explore the potential benefits and challenges.",
                      ],
                    },
                    {
                      compType: "html",
                      compName: "div",
                      compProps: {},
                      eventProps: {},
                      className: "text-gray-500 text-sm mb-4",
                      style: {},
                      script: "",
                      bodyStyle: "",
                      children: ["March 24, 2024 • 12 min read"],
                    },
                    {
                      compType: "html",
                      compName: "a",
                      compProps: { href: "#" },
                      eventProps: {},
                      className: "text-indigo-600 hover:underline",
                      style: {},
                      script: "",
                      bodyStyle: "",
                      children: ["Read More"],
                    },
                  ],
                },
                {
                  compType: "html",
                  compName: "div",
                  compProps: {},
                  eventProps: {},
                  className:
                    "bg-white rounded-lg shadow-lg p-6 hover:shadow-xl transition-shadow",
                  style: {},
                  script: "",
                  bodyStyle: "",
                  children: [
                    {
                      compType: "html",
                      compName: "img",
                      compProps: {
                        src: "https://via.placeholder.com/500x250",
                        alt: "Blog post image",
                      },
                      eventProps: {},
                      className: "w-full h-56 object-cover rounded-md mb-4",
                      style: {},
                      script: "",
                      bodyStyle: "",
                      children: [],
                    },
                    {
                      compType: "html",
                      compName: "h2",
                      compProps: {},
                      eventProps: {},
                      className: "text-2xl font-bold mb-2",
                      style: {},
                      script: "",
                      bodyStyle: "",
                      children: ["Ethics in Artificial Intelligence"],
                    },
                    {
                      compType: "html",
                      compName: "p",
                      compProps: {},
                      eventProps: {},
                      className: "text-gray-700 mb-4",
                      style: {},
                      script: "",
                      bodyStyle: "",
                      children: [
                        "A look into the ethical considerations and challenges in developing AI technology.",
                      ],
                    },
                    {
                      compType: "html",
                      compName: "div",
                      compProps: {},
                      eventProps: {},
                      className: "text-gray-500 text-sm mb-4",
                      style: {},
                      script: "",
                      bodyStyle: "",
                      children: ["April 5, 2024 • 9 min read"],
                    },
                    {
                      compType: "html",
                      compName: "a",
                      compProps: { href: "#" },
                      eventProps: {},
                      className: "text-indigo-600 hover:underline",
                      style: {},
                      script: "",
                      bodyStyle: "",
                      children: ["Read More"],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};

export const selfClosingHTMLTags = [
  "area",
  "base",
  "br",
  "col",
  "embed",
  "hr",
  "img",
  "input",
  "link",
  "meta",
  "param",
  "source",
  "track",
  "wbr",
];
export const classesToObj = (className) => {
  if (typeof className !== "string") {
    console.error("Invalid className:", className);
    return {}; // Return an empty object for invalid input
  }

  const classes = className.split(" ");
  const classObject = {};

  classes.forEach((cls) => {
    if (cls.trim().length > 0) {
      classObject[cls] = true;
    }
  });

  return classObject;
};

export const getHex = (twColor) => {
  if (twColor.startsWith("#")) {
    return twColor;
  } else {
    const [color, shade] = twColor.split("-");
    return shade ? colors[color][Number(shade)] : colors[color];
  }
};

export const setColorProperty = (colorType, colorValue) => {
  let colorVal = getHex(colorValue);
  if (colorType === "grayshade") {
    const scale = chroma.scale(["white", colorVal, "black"]).mode("lab");
    for (let i = 1; i < 10; i++) {
      const shade = scale(i / 10).hex();
      document.documentElement.style.setProperty(`--grayshade-${i}00`, shade);
    }
  } else {
    const rgb = chroma(colorVal).rgb().join(" ");
    document.documentElement.style.setProperty(`--${colorType}`, rgb);
  }
};

export const extractBlogContents = (htmlContent) => {
  // Create a temporary container to parse the HTML content
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = htmlContent;

  // Extract the heading
  const heading = tempDiv.querySelector("h1")?.textContent.trim() || "";

  // Extract the author name
  const authorName =
    tempDiv
      .querySelector("div.flex.items-center.space-x-3.text-sm span")
      ?.textContent.trim() || "";

  // Extract the estimated read time
  const estimatedReadTime =
    Array.from(
      tempDiv.querySelectorAll("div.flex.items-center.space-x-3.text-sm span")
    )
      .find((span) => span.textContent.includes("min read"))
      ?.textContent.trim() || "";

  // Extract a small description (first paragraph of content)
  const smallDescription =
    tempDiv
      .querySelector("section.mt-8.space-y-6.text-gray-300 p")
      ?.textContent.trim() || "";

  // Return extracted data as an object
  return {
    heading,
    authorName,
    estimatedReadTime,
    smallDescription,
  };
};
