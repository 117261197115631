/** @type {import('tailwindcss').Config} */
module.exports = {
  content: ["./src/**/*.{js,jsx,ts,tsx}"],
  theme: {
    fontFamily: {
      sans: ["Inter", "sans-serif"],
    },
    extend: {
      fontFamily: {
        secondary: ["Roboto", "sans-serif"],
        webstitchOld: ['"M PLUS Rounded 1c"', "sans-serif"],
        webstitch: ["Mulish", "sans-serif"],
        heading: "var(--heading-font)",
        body: "var(--body-font)",
      },
      colors: {
        primary: "rgb(var(--primary))",
        secondary: `rgb(var(--secondary))`,
        background: `rgb(var(--background))`,
        foreground: `rgb(var(--foreground))`,
        grayshade: {
          100: `var(--grayshade-100)`,
          200: `var(--grayshade-200)`,
          300: `var(--grayshade-300)`,
          400: `var(--grayshade-400)`,
          500: `var(--grayshade-500)`,
          600: `var(--grayshade-600)`,
          700: `var(--grayshade-700)`,
          800: `var(--grayshade-800)`,
          900: `var(--grayshade-900)`,
        },
      },
    },
  },
  plugins: [require("tailwindcss-animate")],
};
