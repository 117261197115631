import React, { useContext, useEffect, useState } from 'react'
import CanvasContext from '../../../../context/CanvasContext'
import { SpaceContext } from 'react-zoomable-ui';
import AiInputSelected from '../comps/ChatInputSelected';
import AiPromptSelected from '../comps/ChatPromptSelected';
import useRecoilStateToValues from '../../../../hooks/useRecoilStateToValues';
import { jsonToCode } from '../../../../componentStates/codeParser';

const SelectionOverlay = () => {
  const {selectedState, selectedComponentRect, pageState, aiContextExists} = useContext(CanvasContext);
  const context = useContext(SpaceContext);
  const [translatedRect, setTranslatedRect] = useState({});
  const [aiPrompt, setAiPrompt] = useState("");

  const recoilStateToJsonObj = useRecoilStateToValues();

  useEffect(() => {
    if (selectedState?.key !== pageState?.key) {
      const selectedCompJsonObj = recoilStateToJsonObj(selectedState);
      const code = jsonToCode(selectedCompJsonObj);

      if (code?.html) {
        // Define a regular expression to match the attribute 'data-aiprompt' with any value
        const regex = /data-aiprompt\s*=\s*["']([^"']+)["']/;
        const match = code.html.match(regex);
        if (aiContextExists && match) {
          const aiPromptValue = match[1]; // Extract the value of data-aiprompt
          setAiPrompt(aiPromptValue);          
        } else {
          setAiPrompt("");
        }
      }
    }

    if (selectedComponentRect) {
      setTranslatedRect(context.viewPort.translateClientRectToVirtualSpace(selectedComponentRect));
    }
  }, [selectedComponentRect]);

  return (
    <div>
      {(selectedState?.key !== pageState?.key && selectedComponentRect) && (
        <div style={{pointerEvents: "none", position: "absolute", top: `${translatedRect.top}px`, left: `${translatedRect.left}px`}}>
          <div className="border border-red-500 flex items-center" style={{position: "relative", width:`${translatedRect.width}px`, height: `${translatedRect.height}px`}}>
            <div className="px-1 w-fit text-[6px] bg-red-500 text-white font-semibold flex justify-center items-center" style={{position: "absolute", top: `-11px`, left: `-1px`}}>
              {selectedState.key.split("_")[0]}
            </div>
            <div
              style={{ pointerEvents: 'auto', left: `${translatedRect.width / 2}px`, transform: 'translateX(-50%)' }}
              className="absolute top-[-27px] flex gap-1 items-center"
            >
              <AiPromptSelected aiPrompt={aiPrompt}/>
            </div>
            <div style={{pointerEvents: "auto"}} className={`absolute left-[${translatedRect.width+2}px] flex gap-1 items-center`}>
              <AiInputSelected />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default SelectionOverlay;
