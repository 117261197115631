import React, { useCallback, useContext, useEffect, useState } from 'react'
import DialogBox from './DialogBox';
import CanvasContext from '../../../../context/CanvasContext';
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate';
import { BiLoaderAlt } from 'react-icons/bi';
import { IoCheckmark } from "react-icons/io5";
import { RxCrossCircled } from "react-icons/rx";
import { useParams } from 'react-router-dom';


const GlobalCssBox = ({ scriptBoxAppear, setScriptBoxAppear }) => {
  const { siteId } = useParams();
  const { siteRecord, setSiteRecord } = useContext(CanvasContext);
  const [globalCssCode, setGlobalCssCode] = useState(siteRecord?.devTheme && siteRecord.devTheme?.globalCssCode);
  const [updated, setUpdated] = useState('');

  const [isUpdating, setIsUpdating] = useState(false);
  const [updateErr, setUpdateErr] = useState("");

  const codeExample = `<style>
    /*<mobile_marker>*/
    @media (max-width: 640px) { 
      [refs="my-container"] {
        /*your mobile code here*/
      }
    }

    /*<tablet_marker>*/ 
    @media (min-width: 641px) and (max-width: 1024px) { 
     [refs="my-element"] {
        /*your tablet code here*/
      }
    }
   
    [refs="my-section"] {
      /*general code here*/
    }      
</style>`;

  const axiosPrivate = useAxiosPrivate();

  useEffect(() => {

    if (siteRecord?.devTheme && siteRecord.devTheme?.globalCssCode) {
      setGlobalCssCode(siteRecord.devTheme.globalCssCode);
    } 
    
  }, [siteRecord])

  const updateCss = async () => {
    setUpdated('');
    setIsUpdating(true);
    setUpdateErr("");
    try {

      const resp = await axiosPrivate.patch("/website", { id: siteId, globalCssCode });
      if (resp.status == 200) {
        setUpdated('CSS Updated');

        setSiteRecord((site) => {
          return {
            ...site,
            devTheme: {
              ...site.devTheme,
              globalCssCode
            }
          };
        });
      } else {
        setUpdateErr("Error");
      }
    } catch (err) {
      console.log(err);
      setUpdateErr("Error");
    } finally {
      setIsUpdating(false);
    }
  }

  const generateDefaultCss = () => {    
    setGlobalCssCode(codeExample);
    setUpdated('Default CSS Generated');
  }

  return (
    <DialogBox appear={scriptBoxAppear} setAppear={setScriptBoxAppear}>
      <div className='absolute'>
        <div className='bg-black border border-zinc-800 flex flex-col gap-3 text-zinc-300 fixed  left-[515px] z-10 p-4 rounded-xl text-xs w-[550px]'>
          <div className='font-semibold text-base flex items-center justify-between border-b border-zinc-800 pb-2'>
            Global CSS
            {updateErr &&
              <p className='text-red-900 opacity-100 text-xs flex font-semibold gap-1'>
                <RxCrossCircled className='w-4 h-auto' />
                {updateErr}
              </p>
            }
            {updated &&
              <p className='text-lime-600 opacity-100 text-xs flex font-semibold gap-1'>
                <IoCheckmark className='w-4 h-auto' />
                {updated}
              </p>
            }
          </div>
          <div className="leading-relaxed">
            <p>
              <span className="font-bold">Usage: </span>
              Your styles must be wrapped inside <code>&lt;style&gt;</code> tags and should follow the correct format for mobile and tablet responsiveness.
            </p>

            <h3 className="font-bold mt-3">Structure:</h3>
            <ul className="list-none pl-0 space-y-1">
              <li className="pl-6 relative before:absolute before:left-4 before:content-['-']">
                Start with the <code>&lt;style&gt;</code> tag.
              </li>
              <li className="pl-6 relative before:absolute before:left-4 before:content-['-']">
                Place the markers <code>/*&lt;mobile_marker&gt;*/</code> and <code>/*&lt;tablet_marker&gt;*/</code> on a separate line before their corresponding <code>@media</code> queries.
                This formatting is required for the canvas to interpret the styles correctly.
              </li>
              <li className="pl-6 relative before:absolute before:left-4 before:content-['-']">
                Write valid CSS rules inside the <code>@media</code> queries for responsive design.
              </li>
            </ul>
          </div>
          <div className='text-zinc-500 w-full flex flex-col gap-4 text-xs'>
            <div className='flex gap-2'>
              <button
                onClick={generateDefaultCss}
                className='py-2 rounded-md text-zinc-200 bg-blue-600 flex-1 font-semibold flex justify-center hover:bg-blue-500 transition'
              >
                Generate from template
              </button>
              <button onClick={updateCss} className='py-2 rounded-md text-zinc-200 bg-lime-600 flex-1 font-semibold flex justify-center disabled:bg-lime-800' disabled={isUpdating}>{!isUpdating ? "Update" : <BiLoaderAlt className='w-4 h-4 animate-spin' />}</button>
            </div>
            <textarea title='Write your scripts with <script> tags' placeholder={codeExample}
              value={globalCssCode} onChange={(e) => setGlobalCssCode(e.target.value)} rows="20" className='border border-zinc-800 text-md w-full outline-none bg-zinc-900 px-4 py-3 rounded-md resize-none text-zinc-300 placeholder:text-zinc-600' />

          </div>
        </div>
      </div>
    </DialogBox>
  )
}

export default GlobalCssBox