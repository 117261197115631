import resolveConfig from "tailwindcss/resolveConfig";
import tailwindConfig from "../../../../tailwind.config";

const fullConfig = resolveConfig(tailwindConfig);
const tailwindScales = {
  // Layout
  display: [
    "block",
    "inline-block",
    "inline",
    "flex",
    "inline-flex",
    "grid",
    "inline-grid",
    "hidden",
    "flow-root",
    "contents",
    "table",
    "table-row",
    "table-cell",
    "list-item",
  ],
  float: ["right", "left", "none"],
  clear: ["left", "right", "both", "none"],
  objectFit: ["contain", "cover", "fill", "none", "scale-down"],
  objectPosition: [
    "bottom",
    "center",
    "left",
    "left-bottom",
    "left-top",
    "right",
    "right-bottom",
    "right-top",
    "top",
  ],
  overflow: ["auto", "hidden", "visible", "scroll"],
  overscroll: ["auto", "contain", "none"],
  position: ["static", "fixed", "absolute", "relative", "sticky"],
  top: ["0", "auto", "full"],
  right: ["0", "auto", "full"],
  bottom: ["0", "auto", "full"],
  left: ["0", "auto", "full"],
  visibility: ["visible", "hidden", "collapse"],
  zIndex: ["0", "10", "20", "30", "40", "50", "auto"],

  // Flexbox
  flex: ["1", "auto", "initial", "none"],
  flexDirection: ["row", "row-reverse", "col", "col-reverse"],
  flexWrap: ["wrap", "wrap-reverse", "nowrap"],
  alignItems: ["stretch", "start", "center", "end", "baseline"],
  alignContent: ["start", "center", "end", "between", "around", "evenly"],
  alignSelf: ["auto", "start", "center", "end", "stretch", "baseline"],
  justifyContent: ["start", "center", "end", "between", "around", "evenly"],
  justifyItems: ["start", "center", "end", "stretch"],
  justifySelf: ["auto", "start", "center", "end", "stretch"],
  order: [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
    "first",
    "last",
    "none",
  ],

  // Grid
  gridTemplateColumns: [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
    "none",
  ],
  gridColumn: [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
    "auto",
    "span-1",
    "span-2",
    "span-3",
    "span-4",
    "span-5",
    "span-6",
    "span-7",
    "span-8",
    "span-9",
    "span-10",
    "span-11",
    "span-12",
    "span-full",
  ],
  gridColumnStart: [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
    "13",
    "auto",
  ],
  gridColumnEnd: [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
    "13",
    "auto",
  ],
  gridTemplateRows: [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
    "none",
  ],
  gridRow: [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
    "auto",
    "span-1",
    "span-2",
    "span-3",
    "span-4",
    "span-5",
    "span-6",
    "span-7",
    "span-8",
    "span-9",
    "span-10",
    "span-11",
    "span-12",
    "span-full",
  ],
  gridRowStart: [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
    "13",
    "auto",
  ],
  gridRowEnd: [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
    "13",
    "auto",
  ],
  gap: [
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "8",
    "10",
    "12",
    "16",
    "20",
    "24",
    "32",
    "40",
    "48",
    "56",
    "64",
    "72",
    "80",
    "96",
  ],
  gapX: [
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "8",
    "10",
    "12",
    "16",
    "20",
    "24",
    "32",
    "40",
    "48",
    "56",
    "64",
    "72",
    "80",
    "96",
  ],
  gapY: [
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "8",
    "10",
    "12",
    "16",
    "20",
    "24",
    "32",
    "40",
    "48",
    "56",
    "64",
    "72",
    "80",
    "96",
  ],

  // Spacing
  padding: [
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "8",
    "10",
    "12",
    "16",
    "20",
    "24",
    "32",
    "40",
    "48",
    "56",
    "64",
    "72",
    "80",
    "96",
  ],
  paddingX: [
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "8",
    "10",
    "12",
    "16",
    "20",
    "24",
    "32",
    "40",
    "48",
    "56",
    "64",
    "72",
    "80",
    "96",
  ],
  paddingY: [
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "8",
    "10",
    "12",
    "16",
    "20",
    "24",
    "32",
    "40",
    "48",
    "56",
    "64",
    "72",
    "80",
    "96",
  ],
  margin: [
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "8",
    "10",
    "12",
    "16",
    "20",
    "24",
    "32",
    "40",
    "48",
    "56",
    "64",
    "72",
    "80",
    "96",
    "auto",
  ],
  marginX: [
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "8",
    "10",
    "12",
    "16",
    "20",
    "24",
    "32",
    "40",
    "48",
    "56",
    "64",
    "72",
    "80",
    "96",
    "auto",
  ],
  marginY: [
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "8",
    "10",
    "12",
    "16",
    "20",
    "24",
    "32",
    "40",
    "48",
    "56",
    "64",
    "72",
    "80",
    "96",
    "auto",
  ],

  // Typography
  fontSize: [
    "xs",
    "sm",
    "base",
    "lg",
    "xl",
    "2xl",
    "3xl",
    "4xl",
    "5xl",
    "6xl",
    "7xl",
    "8xl",
    "9xl",
  ],
  fontWeight: [
    "thin",
    "extralight",
    "light",
    "normal",
    "medium",
    "semibold",
    "bold",
    "extrabold",
    "black",
  ],
  textAlign: ["left", "center", "right", "justify"],
  textColor: [
    "transparent",
    "black",
    "white",
    "gray-500",
    "red-500",
    "blue-500",
    "green-500",
  ],
  textOpacity: [
    "0",
    "5",
    "10",
    "20",
    "25",
    "30",
    "40",
    "50",
    "60",
    "70",
    "75",
    "80",
    "90",
    "95",
    "100",
  ],
  textDecoration: ["underline", "line-through", "none"],
  textTransform: ["uppercase", "lowercase", "capitalize", "none"],

  // Backgrounds
  backgroundColor: [
    "transparent",
    "black",
    "white",
    "gray-500",
    "red-500",
    "blue-500",
    "green-500",
  ],
  backgroundOpacity: [
    "0",
    "5",
    "10",
    "20",
    "25",
    "30",
    "40",
    "50",
    "60",
    "70",
    "75",
    "80",
    "90",
    "95",
    "100",
  ],
  backgroundPosition: [
    "bottom",
    "center",
    "left",
    "left-bottom",
    "left-top",
    "right",
    "right-bottom",
    "right-top",
    "top",
  ],
  backgroundSize: ["auto", "cover", "contain"],

  // Borders
  borderWidth: ["0", "1", "2", "4", "8"],
  borderColor: [
    "transparent",
    "black",
    "white",
    "gray-500",
    "red-500",
    "blue-500",
    "green-500",
  ],
  borderOpacity: [
    "0",
    "5",
    "10",
    "20",
    "25",
    "30",
    "40",
    "50",
    "60",
    "70",
    "75",
    "80",
    "90",
    "95",
    "100",
  ],
  borderRadius: ["none", "sm", "md", "lg", "xl", "2xl", "3xl", "full"],
  borderStyle: ["solid", "dashed", "dotted", "double", "none"],

  // Effects
  boxShadow: ["sm", "md", "lg", "xl", "2xl", "inner", "none"],
  opacity: [
    "0",
    "5",
    "10",
    "20",
    "25",
    "30",
    "40",
    "50",
    "60",
    "70",
    "75",
    "80",
    "90",
    "95",
    "100",
  ],

  // Interactivity
  cursor: ["auto", "default", "pointer", "wait", "text", "move", "not-allowed"],
  userSelect: ["none", "text", "all", "auto"],
  pointerEvents: ["none", "auto"],
  resize: ["none", "both", "horizontal", "vertical"],
};
// List of Tailwind utility prefixes
const tailwindUtilityPrefixes = [
  // Layout
  "container-",
  "display-",
  "float-",
  "clear-",
  "object-",
  "overflow-",
  "overscroll-",
  "position-",
  "inset-",
  "top-",
  "right-",
  "bottom-",
  "left-",
  "visibility-",
  "z-",

  // Flexbox
  "flex-",
  "flex-direction-",
  "flex-wrap-",
  "align-",
  "justify-",
  "place-",
  "order-",
  "gap-",

  // Grid
  "grid-",
  "grid-cols-",
  "grid-rows-",
  "col-",
  "col-start-",
  "col-end-",
  "row-",
  "row-start-",
  "row-end-",
  "auto-cols-",
  "auto-rows-",
  "gap-",
  "gap-x-",
  "gap-y-",

  // Spacing
  "p-",
  "px-",
  "py-",
  "pt-",
  "pr-",
  "pb-",
  "pl-",
  "m-",
  "mx-",
  "my-",
  "mt-",
  "mr-",
  "mb-",
  "ml-",
  "space-x-",
  "space-y-",

  // Sizing
  "w-",
  "min-w-",
  "max-w-",
  "h-",
  "min-h-",
  "max-h-",

  // Typography
  "text-",
  "font-",
  "text-size-",
  "leading-",
  "tracking-",
  "text-opacity-",
  "text-decoration-",
  "text-transform-",
  "text-align-",
  "text-overflow-",
  "whitespace-",
  "word-break-",

  // Backgrounds
  "bg-",
  "bg-opacity-",
  "bg-gradient-",
  "from-",
  "via-",
  "to-",

  // Borders
  "border-",
  "border-x-",
  "border-y-",
  "border-t-",
  "border-r-",
  "border-b-",
  "border-l-",
  "border-opacity-",
  "rounded-",
  "rounded-t-",
  "rounded-r-",
  "rounded-b-",
  "rounded-l-",
  "rounded-tl-",
  "rounded-tr-",
  "rounded-br-",
  "rounded-bl-",
  "border-style-",

  // Effects
  "shadow-",
  "opacity-",
  "mix-blend-",
  "background-blend-",

  // Filters
  "filter-",
  "backdrop-filter-",
  "blur-",
  "brightness-",
  "contrast-",
  "drop-shadow-",
  "grayscale-",
  "hue-rotate-",
  "invert-",
  "saturate-",
  "sepia-",

  // Transitions and Animation
  "transition-",
  "duration-",
  "ease-",
  "delay-",
  "animate-",

  // Transforms
  "transform-",
  "origin-",
  "scale-",
  "rotate-",
  "translate-",
  "skew-",

  // Interactivity
  "cursor-",
  "pointer-events-",
  "resize-",
  "scroll-",
  "scroll-m-",
  "scroll-p-",
  "snap-",
  "touch-",
  "select-",
  "will-change-",

  // SVG
  "fill-",
  "stroke-",
  "stroke-width-",

  // Accessibility
  "sr-",
  "not-sr-",
];

const isValidTailwindClass = (className) => {
  // Regex to allow valid Tailwind characters, including square brackets for arbitrary values
  const tailwindRegex = /^[a-z0-9-:\/\[\]]+$/;
  if (!tailwindRegex.test(className)) {
    return {
      isValid: false,
      errorMessage: "Invalid characters in class name.",
    };
  }

  // List of valid media queries
  const mediaQueries = {
    sm: { minWidth: 475 },
    md: { minWidth: 800 },
    lg: { minWidth: 1280 },
  };

  // Split the class name into parts (e.g., "sm:hover:text-red-500" => ["sm", "hover", "text-red-500"])
  const parts = className.split(":");
  let utilityClass = parts[parts.length - 1];

  // Validate media queries and variants
  for (let i = 0; i < parts.length - 1; i++) {
    const query = parts[i];
    if (!mediaQueries[query]) {
      return {
        isValid: false,
        errorMessage: `Invalid media query: ${query}.`,
      };
    }
  }

  // Skip validation for arbitrary values (e.g., "w-[200px]", "text-[#ff0000]")
  if (utilityClass.includes("[")) {
    return { isValid: true, errorMessage: "" };
  }

  // Check if the utility class starts with a valid Tailwind utility prefix
  const hasValidPrefix = tailwindUtilityPrefixes.some((prefix) =>
    utilityClass.startsWith(prefix)
  );

  if (!hasValidPrefix) {
    return {
      isValid: false,
      errorMessage: `Invalid Tailwind utility prefix: ${utilityClass}.`,
    };
  }

  // Split utility and value (e.g., "text-red-500" => ["text", "red-500"])
  const [utility, value] = utilityClass.split("-");

  // Validate utility and value if the utility exists in tailwindScales
  if (tailwindScales[utility]) {
    if (!tailwindScales[utility].includes(value)) {
      return {
        isValid: false,
        errorMessage: `Invalid value for utility: ${utility}.`,
      };
    }
  }

  // If the utility is not in tailwindScales, assume it's valid (e.g., custom classes or plugins)
  return { isValid: true, errorMessage: "" };
};

export default isValidTailwindClass;
