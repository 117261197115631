import React, { useContext, useEffect, useState } from "react";
import CanvasContext from "../../../../context/CanvasContext";
import classNames from "classnames";
import useTrackedSetRecoilState from "../../../../hooks/useTrackedSetRecoilState";
import { classesToObj } from "../../../../helpers/helpers";
import { useRecoilValue } from "recoil";
import isValidTailwindClass from "./tailwindValidator";

const CustomCss = () => {
  const { SCREEN, screenType, selectedState } = useContext(CanvasContext);
  const state = useRecoilValue(selectedState);
  const setState = useTrackedSetRecoilState(selectedState);
  const [customClasses, setCustomClasses] = useState([]);
  const [inputTailwindValue, setInputTailiwindValue] = useState("");
  const [inputCssValue, setInputCssValue] = useState("");
  const [errorTailwindMessage, setErrorTailwindMessage] = useState("");
  const [errorCssMessage, setErrorCssMessage] = useState("");
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  useEffect(() => {
    const classNameObj = classesToObj(state.className);
    const initialClasses = Object.keys(classNameObj);
    setCustomClasses(initialClasses);
  }, [state]);

  const updateState = (newClasses) => {
    setState((prevState) => {
      const updatedClassName = classNames(...newClasses);
      return { ...prevState, className: updatedClassName };
    });
  };

  const handleInputTailwindChange = (e) => {
    setInputTailiwindValue(e.target.value);
    setErrorTailwindMessage("");
  };

  const handleAddTailwindClass = () => {
    const newClass = inputTailwindValue.trim();
    if (!newClass) return;

    const validationResult = isValidTailwindClass(newClass);
    if (!validationResult.isValid) {
      setErrorTailwindMessage(validationResult.errorMessage);
      return;
    }

    if (customClasses.includes(newClass)) {
      setErrorTailwindMessage("Class already exists.");
      return;
    }

    // Check if the class has a media query prefix (sm:, md:, lg:)
    const parts = newClass.split(":");
    const hasMediaQuery = parts.length > 1;

    // Add the class to the list of custom classes
    const updatedTailwindClasses = [...customClasses, newClass];
    setCustomClasses(updatedTailwindClasses);

    // Update the state with the new class
    updateState(updatedTailwindClasses);

    // Clear the input and error message
    // setInputTailiwindValue("");
    setErrorTailwindMessage("");
  };

  const handleInputCssChange = (e) => {
    // setInputCssValue(e.target.value);
    setErrorTailwindMessage("");
  };

  const handleAddCssClass = () => {
    const newClass = inputCssValue.trim();
    if (!newClass) return;

    const isValidInlineCSS = (css) => {
      try {
        const style = document.createElement("div").style;
        css.split(";").forEach((rule) => {
          if (rule.trim()) {
            const [property, value] = rule.split(":").map((str) => str.trim());
            if (!property || !value || !(property in style)) {
              throw new Error("Invalid CSS property or value");
            }
          }
        });
        return true;
      } catch {
        return false;
      }
    };

    if (isValidInlineCSS(newClass)) {
      const cssObject = {};
      newClass.split(";").forEach((rule) => {
        if (rule.trim()) {
          const [property, value] = rule.split(":").map((str) => str.trim());
          cssObject[property] = value;
        }
      });

      setState((prevState) => {
        const updatedStyle = { ...prevState.style, ...cssObject };
        return { ...prevState, style: updatedStyle };
      });

      // setInputCssValue("");
      setErrorCssMessage("");
      return;
    }

    setErrorCssMessage("Invalid CSS style. Please try again.");
  };

  const handleRemoveClass = (clsToRemove) => {
    const updatedClasses = customClasses.filter((cls) => cls !== clsToRemove);
    setCustomClasses(updatedClasses);
    updateState(updatedClasses);
  };

  // const toggleDialog = () => {
  //   setIsDialogOpen(!isDialogOpen);
  // };

  // Separate classes into Tailwind and Custom CSS classes
  const tailwindClasses = customClasses.filter(isValidTailwindClass);
  const customCssClasses = customClasses.filter(
    (cls) => !isValidTailwindClass(cls)
  );

  return (
    <>
      <p className="flex items-center text-zinc-400 text-xs w-16 h-8 mt-4">
        Add Tailwind classes
      </p>
      <div className="col-span-2 flex flex-col gap-0 relative h-20 mb-4">
        {/* Increased height and margin-bottom for better spacing */}
        <div className="flex mt-2 relative h-full">
          {/* Set height to full to ensure proper alignment */}
          <textarea
            type="text"
            className={`w-full pl-3 pr-2 py-1 text-sm text-zinc-400 bg-zinc-900 border rounded-md outline-none ${
              errorTailwindMessage ? "border-red-500" : "border-zinc-700"
            }`}
            value={inputTailwindValue}
            onChange={handleInputTailwindChange}
            onKeyDown={(e) => {
              if (e.key === "Enter") handleAddTailwindClass();
            }}
            placeholder="Enter class"
          />
          {/* Optional: Uncomment this button if you want to add a dropdown or other action */}
          {/* <button
      className="absolute right-2 top-1/2 transform -translate-y-1/2 text-zinc-400 hover:text-zinc-200"
      onClick={toggleDialog}
    >
      ▼
    </button> */}
        </div>
        {/* Display error message if `errorTailwindMessage` is not empty */}
        {errorTailwindMessage && (
          <span className="text-red-500 text-xs mt-1">
            {errorTailwindMessage}
          </span>
        )}
      </div>

      {isDialogOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white rounded-lg p-4 shadow-lg w-80">
            <h2 className="text-lg font-bold mb-4">Added Classes</h2>
            <div className="flex flex-col gap-2">
              {tailwindClasses.length > 0 && (
                <div>
                  <h3 className="font-bold text-sm">Tailwind Classes</h3>
                  {tailwindClasses.map((cls) => (
                    <div
                      key={cls}
                      className="rounded-md bg-slate-800 px-3 py-1 text-sm text-white border-l-4 border-blue-500 flex justify-between items-center"
                    >
                      <span>{cls}</span>
                      <button
                        className="text-red-500 font-bold ml-2"
                        onClick={() => handleRemoveClass(cls)}
                      >
                        &times;
                      </button>
                    </div>
                  ))}
                </div>
              )}
              {customCssClasses.length > 0 && (
                <div>
                  <h3 className="font-bold text-sm">Custom CSS Classes</h3>
                  {customCssClasses.map((cls) => (
                    <div
                      key={cls}
                      className="rounded-md bg-slate-800 px-3 py-1 text-sm text-white border-l-4 border-blue-500 flex justify-between items-center"
                    >
                      <span>{cls}</span>
                      <button
                        className="text-red-500 font-bold ml-2"
                        onClick={() => handleRemoveClass(cls)}
                      >
                        &times;
                      </button>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CustomCss;
