import React, { useContext, useEffect, useState } from 'react'
import CanvasContext from '../../../../context/CanvasContext';
import { useRecoilValue } from 'recoil';
import useTrackedSetRecoilState from '../../../../hooks/useTrackedSetRecoilState';

const ElementAiPrompt = () => {

  const [promptValue, setPromptValue] = useState("");

  const { selectedState } = useContext(CanvasContext);
  const state = useRecoilValue(selectedState);
  const setState = useTrackedSetRecoilState(selectedState);

  const updatePrompt = async (value) => {
    if (!selectedState) {
      return;
    }
    setState((prevState) => ({
      ...prevState,
      compProps: { ...prevState.compProps, 'data-aiprompt': `${value ? value : ''}` },
    }));
    setPromptValue(value);
  }

  useEffect(() => {
    if (state.compProps?.['data-aiprompt']) {
      setPromptValue(state.compProps?.['data-aiprompt']);      
    } else {
      setPromptValue("");      
    }
  }, [selectedState]);

  return (
    <div className='flex flex-col gap-3 tracking-wider border-b border-zinc-800 pb-5' title='AI Prompt'>
      <p className='text-zinc-200 font-semibold text-xs'>Element AI Prompt</p>
      <div className="flex gap-2 text-stone-400">
          <textarea 
            className='text-xs bg-zinc-900 rounded-lg py-2 px-4 w-full outline-none border border-transparent focus:border-lime-600 resize-none max-h-32 customScroll'
            value={promptValue}
            onChange={(e) => updatePrompt(e.target.value)}
            placeholder='Type a prompt ...'
            rows={3}
          />
      </div>
    </div>
  );
}

export default ElementAiPrompt