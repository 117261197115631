import { useContext, useEffect, useRef } from "react";
import CanvasContext from "../context/CanvasContext";
import { useSetRecoilState } from "recoil";

const useUndo = () => {
  const {
    setStateChangeHistory,
    lastChangedState,
    setLastChangedState,
    dummyAtom,
  } = useContext(CanvasContext);
  const setState = useSetRecoilState(lastChangedState);

  const handleKeyDown = (event) => {
    if (
      event.target.tagName !== "INPUT" &&
      event.target.tagName !== "TEXTAREA" &&
      (event.metaKey || event.ctrlKey) &&
      event.key === "z"
    ) {
      setStateChangeHistory((hist) => {
        const newHist = [...hist];
        const lastState = newHist.pop();
        if (lastState) {
          setState(lastState.prev);
        }
        if (newHist.length > 0) {
          setLastChangedState(newHist[newHist.length - 1].atom);
        } else {
          setLastChangedState(dummyAtom);
        }
        return newHist;
      });
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [lastChangedState]); // this dependency array ensures the handleKeyDown is updated with correct `setState` at every change of state
};

export default useUndo;
