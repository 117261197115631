import React, { useContext, useEffect, useState } from 'react'
import CanvasContext from '../../../../context/CanvasContext';
import PropOpen from './PropOpen';
import { FaFileImport } from 'react-icons/fa';
import GlobalCssBox from './GlobalCssBox';
import { useRecoilValue } from 'recoil';
import useTrackedSetRecoilState from '../../../../hooks/useTrackedSetRecoilState';

const GlobalCssConfiguration = () => {

  const [open, setOpen] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [elementRefsValue, setElementRefsValue] = useState("");

  const { selectedState } = useContext(CanvasContext);
  const state = useRecoilValue(selectedState);
  const setState = useTrackedSetRecoilState(selectedState);

  const [scriptBox, setScriptBox] = useState(false);

  const updateElementRefs = async (value) => {
    if (!selectedState) {
      return;
    }
    setState((prevState) => ({
      ...prevState,
      compProps: { ...prevState.compProps, 'refs': `${value ? value : ''}` },
    }));
    setElementRefsValue(value);
  }

  useEffect(() => {
    if (state.compProps?.['refs']) {
      setElementRefsValue(state.compProps?.['refs']);
      setOpen(true);
    } else {
      setElementRefsValue("");
      setOpen(false);
    }
  }, [selectedState]);

  return (
    <PropOpen title="Global CSS Configuration" open={open} setOpen={setOpen} gridCols='5'>
      <p className="col-span-2 flex items-center text-zinc-400 text-xs">
        Element Ref
      </p>
      <div className={`col-span-3 relative propUnitBox w-full p-[6px] pl-2 flex items-center justify-between cursor-pointer ${isFocused ? 'border border-lime-600' : ''}`}>
        <input type="text" className='bg-transparent outline-none h-3 w-[100%] px-1 py-2 text-xs tracking-widest text-zinc-400 placeholder:text-zinc-500'
          value={elementRefsValue}
          onChange={(e) => updateElementRefs(e.target.value)}
          placeholder='e.g. btn-group nav'
          onFocus={() => setIsFocused(true)}
          onBlur={() => { setTimeout(() => setIsFocused(false), 200) }}
        />
      </div>

      <p className="col-span-2 flex items-center text-zinc-400 text-xs">Global CSS</p>
      <div onClick={(e) => {
        e.stopPropagation();
        setScriptBox(true);

      }} className="col-span-3 flex propUnitBox w-full p-[6px] pl-2 flex items-center justify-between cursor-pointer">
        Open
        <GlobalCssBox
          scriptBoxAppear={scriptBox}
          setScriptBoxAppear={setScriptBox}
        />
        <FaFileImport className="w-5 h-auto" />
      </div>

    </PropOpen>

  )
}

export default GlobalCssConfiguration